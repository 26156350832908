import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { createOptions } from "../../../../utils";
import { getSupplierAccountOptions } from "../../../../selectors/dropdowns";
import { Select } from "../../../helpers";
import { FilterMainComponentProps } from "../Filter";

const SupplierSelect = ({
  values,
  onChange,
  placeholder = "All",
}: FilterMainComponentProps) => {
  const rawOptions = useSelector(getSupplierAccountOptions);
  const supplierOptions = useMemo(
    () => createOptions(rawOptions, "key", "value", { addAll: true }),
    [rawOptions],
  );

  return (
    <Select
      value={values}
      // @ts-ignore
      onChange={(e: { value: string; label: string }) =>
        onChange(e?.value || null)
      }
      isClearable={values !== ""}
      // @ts-ignore
      options={supplierOptions}
      placeholder={placeholder}
    />
  );
};

export default SupplierSelect;
